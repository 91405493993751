import React, { FunctionComponent } from 'react';

import { Container, Col, Row } from 'react-bootstrap';
import { Binoculars, People, Cart2, ExclamationCircle, ChatRightText, SortNumericUpAlt } from 'react-bootstrap-icons';

import { Recipe } from '../model/Recipe';
import { RecipeScoring } from '../model/Scoring';

type RecipeQuickViewProps = {
    recipe: Recipe,
    scoring?: RecipeScoring
}

export const RecipeQuickView: FunctionComponent<RecipeQuickViewProps> = ({ recipe, scoring }) => {

    const c = new Intl.Collator();
    const ingredients = recipe.getIngredients().map(r => r.getName())
        .sort(c.compare)
        .map(n => <Col key={n} sm="auto" className="border mt-1 ml-2 pt-2 pb-2">{n}</Col>)

    const labels = recipe.getLabels()
        .sort(c.compare)
        .map(n => <Col key={n} sm="auto" className="border mt-1 ml-2 pt-2 pb-2">{n}</Col>)

    const scoringLines = scoring &&
        scoring.getScorings().map(oneScore =>
            <Row key={oneScore[0].getName()}>
                <Col xs="2">
                    {oneScore[1].toFixed(2)}
                </Col>
                <Col xs="1">
                    ←
                </Col>
                <Col>
                    {oneScore[0].getName()}
                </Col>
            </Row>);

    return (
        <Container className="pl-0 pr-2">
            {recipe.getComment() &&
                <Row className="pb-2">
                    <Col sm="auto"><ChatRightText height="18" /></Col>
                    <Col sm="auto">{recipe.getComment()}</Col>
                </Row>
            }
            {recipe.getServing() &&
                <Row className="pb-2">
                    <Col sm="auto"><People height="18" /></Col>
                    <Col sm="auto">{recipe.getServing()}</Col>
                </Row>
            }
            {recipe.getSource() &&
                <Row className="pb-2">
                    <Col sm="auto"><Binoculars height="18" /></Col>
                    <Col sm="auto">{recipe.getSource()}</Col>
                </Row>
            }
            <Row className="pb-2">
                <Col xs={1}>
                    <Cart2 height="18" />
                </Col>
                <Col>
                    <Container fluid className="pl-0">
                        <Row>
                            {ingredients}
                        </Row>
                    </Container>
                </Col>
            </Row>
            {recipe.getLabels().length > 0 &&
                <Row className="pb-2">
                    <Col xs={1}>
                        <ExclamationCircle height="18" />
                    </Col>
                    <Col>
                        <Container fluid className="pl-0">
                            <Row>
                                {labels}
                            </Row>
                        </Container>
                    </Col>
                </Row>
            }
            {scoring &&
                <Row>
                    <Col xs={1}>
                        <SortNumericUpAlt height="18" />
                    </Col>
                    <Col>
                        <Container className="ml-0 pl-0">
                            {scoringLines}
                            <Row>
                                <Col xs="2" className="border">
                                    {scoring.getSum().toFixed(2)}
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            }
        </Container>
    )
}
