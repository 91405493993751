import { Index, createIndex, addDocumentToIndex, removeDocumentFromIndex } from "ndx";
import { query, QueryResult } from "ndx-query";

import { Recipe } from "../model/Recipe";

import { words } from "lodash";
import { Map } from "immutable";

// It is not quite immutable only looks like that. The underlying full text search index
// is mutable, so be sure that old versions are not used. 
export class RecipeStore {
  private readonly recipesMap: Map<string, Recipe>;
  private readonly index: Index<string>;

  private readonly fieldAccessors = [
    (r: Recipe) => r.getTitle(),
    (r: Recipe) => r.getComment() ?? "",
    (r: Recipe) => r.getSource() ?? "",
    (r: Recipe) => r.getIngredients().map((i) => i.getName()).join(" "),
    (r: Recipe) => r.getLabels().join(" ")
  ];

  private readonly fieldBoostFactors = [2, 1, 1, 1, 2];

  constructor(index?: Index<string>, recipesMap?: Map<string, Recipe>) {
    this.index = index ? index : createIndex<string>(this.fieldAccessors.length);
    this.recipesMap = recipesMap ? recipesMap : Map({});
  }

  private termFilter = (term: string) => {
    return term
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  public static new = () => {
    return new RecipeStore();
  };

  // `add()` function will add documents to the index.
  public add = (r: Recipe) => {
    let key = r.getId();

    let toUpdate = true;

    if (this.recipesMap.has(key)) {
      if (JSON.stringify(this.recipesMap.get(key)) === JSON.stringify(r)) {
        toUpdate = false;
      }
    }

    if (toUpdate) {
      // Don't add the same document twice, ndx becomes crazy...
      this.delete(key);
      let newMap = this.recipesMap.set(key, r);

      addDocumentToIndex(
        this.index,
        this.fieldAccessors,
        // Tokenizer is a function that breaks text into words, phrases, symbols, or other meaningful elements
        // called tokens.
        // Lodash function `words()` splits string into an array of its words, see https://lodash.com/docs/#words for
        // details.
        words,
        // Filter is a function that processes tokens and returns terms, terms are used in Inverted Index to
        // index documents.
        this.termFilter,
        // Document key, it can be a unique document id or a reference to a document if you want to store all documents
        // in memory.
        key,
        // Document.
        r
      );

      return new RecipeStore(this.index, newMap);
    } else {
      return this;
    }
  };

  public addAll = (recipes: Recipe[]) => {
    return recipes.reduce((idx: RecipeStore, recipe: Recipe) => {
      return idx.add(recipe);
    }, this);
  };

  // `search()` function will be used to perform queries.
  public search = (q: string) =>
    query<string>(
      this.index,
      this.fieldBoostFactors,
      // BM25 ranking function constants:
      1.2, // BM25 k1 constant, controls non-linear term frequency normalization (saturation).
      0.75, // BM25 b constant, controls to what degree document length normalizes tf values.
      words,
      this.termFilter,
      // Set of removed documents, in this example we don't want to support removing documents from the index,
      // so we can ignore it by specifying this set as `undefined` value.
      undefined,
      q
    ).reduce((acc: QueryResult<Recipe>[], res) => {
      let r = this.recipesMap.get(res.key);
      if (r) {
        acc.push({ key: r, score: res.score });
      }
      return acc;
    }, []);

  public all = () => {
    return Array.from(this.recipesMap.values());
  };

  public get = (key: string) => {
    return this.recipesMap.get(key);
  };

  public delete = (key: string) => {
    if (this.recipesMap.has(key)) {
      let newMap = this.recipesMap.delete(key);
      removeDocumentFromIndex(this.index, new Set(), key);
      return new RecipeStore(this.index, newMap);
    } else {
      return this;
    }
  };

  public getAll = (keys: string[]) => {
    return keys.reduce((acc: Recipe[], key) => {
      let r = this.recipesMap.get(key);
      if (r) {
        acc.push(r);
      }
      else {
        acc.push(Recipe.error)
      }
      return acc;
    }, []);
  };

  public count = () => {
    return this.recipesMap.size;
  };
}
