import { WorkspaceJSON } from '../model/Workspace'
import { CloudProvider, CloudProviderJSON } from './CloudProvider';
import axios from 'axios'

interface GoogleDriveStateJSON {
    accessToken?: string;
}

// https://developers.google.com/drive/api/v3/reference

export class GoogleDrive implements CloudProvider {

    private constructor(accessToken?: string) {
        this.accessToken = accessToken;
    }

    static new() {
        return new GoogleDrive(undefined);
    }

    static withAccessToken(accessToken: string, data: string) {
        return new GoogleDrive(accessToken);
    }

    static deserialize(json: CloudProviderJSON) {
        let data = json.providerData as GoogleDriveStateJSON;
        return new GoogleDrive(data.accessToken);
    }

    getType() {
        return "googleDrive";
    }

    serialize() {
        return {
            type: "googleDrive",
            providerData: {
                accessToken: this.accessToken
            }
        }
    }

    signIn() {
        oauthSignIn();
    }

    isSignedIn() {
        return !!this.accessToken;
    }

    getConnection() {
        const instance = axios.create({
            baseURL: 'https://www.googleapis.com'
        });

        // Alter defaults after instance has been created
        instance.defaults.headers.common['Authorization'] = "Bearer " + this.accessToken;
        instance.defaults.params = {}
        instance.defaults.params['key'] = "AIzaSyDy0nvvx_vvBKrzqB3_8fBdi5FOeAmFbjc"

        return instance;
    }

    async findFile() {
        let conn = this.getConnection();

        let res = await conn.get("/drive/v3/files", {
            params: {
                //spaces: "appDataFolder",
                q: "name = 'mealee.json' and trashed = false",
                orderBy: "modifiedTime desc"
            }
        })

        let files = res.data.files;

        if (files.length > 0) {
            return files[0].id as string
        }
        else {
            return null;
        }
    }

    async upload(data: Blob) {
        let conn = this.getConnection();

        let fileId = await this.findFile();

        const formData = new FormData();
        formData.append('metadata', new Blob([
            JSON.stringify({ name: "mealee.json"/*, parents: ["appDataFolder"]*/ })],
            {
                type: "application/json; charset=UTF-8"
            }));
        formData.append('media', data)

        if (fileId) {
            return conn.patch("upload/drive/v3/files/" + fileId, formData, {
                params: {
                    uploadType: "multipart"
                }
            }).then(function (response) {
                return;
            })
        }
        else {
            return conn.post("upload/drive/v3/files", formData, {
                params: {
                    uploadType: "multipart"
                }
            }).then(function (response) {
                return;
            })
        }
    }

    async download() {
        let conn = this.getConnection();

        let fileId = await this.findFile();

        if (!fileId) {
            return null;
        }
        else {
            return conn.get("drive/v3/files/" + fileId, {
                params: {
                    alt: "media"
                }
            }).then(function (response) {
                return response.data as WorkspaceJSON;
            })
        }
    }

    private accessToken?: string;
}

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function oauthSignIn() {
    // Google's OAuth 2.0 endpoint for requesting an access token
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    interface Parameters {
        [key: string]: string;
    }

    // Parameters to pass to OAuth 2.0 endpoint.
    const params = {
        'client_id': '472196697807-0s56tl8phm98iohcevrnnvde1l7hrvqg.apps.googleusercontent.com',
        'redirect_uri': 'https://mealee.dlacko.org/google-signin',
        'response_type': 'token',
        'scope': 'https://www.googleapis.com/auth/drive.file',
        'include_granted_scopes': 'true',
        'state': '' // TODO
    } as Parameters;

    // Add form parameters as hidden input values.
    for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
}