import React, { FunctionComponent, useState } from 'react';

import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ShoppingListItem } from '../service/PlanExporterService'

type ShoppingListDialogProps = {
    shoppingList: ShoppingListItem[],
    onClose: () => void
}

// Does not work with accents
function textToBold(text: string) {
    function translate(char: string) {
        let diff;
        if (/[A-Z]/.test(char)) {
            diff = ("𝗔".codePointAt(0) ?? 0) - ("A".codePointAt(0) ?? 0);
        }
        else {
            diff = ("𝗮".codePointAt(0) ?? 0) - ("a".codePointAt(0) ?? 0);
        }
        return String.fromCodePoint((char.codePointAt(0) ?? 0) + diff);
    }
    return text.replace(/[A-Za-z]/g, translate);
}

function getShoppingListText(shoppingList: ShoppingListItem[], format: string) {
    let lines: string[] = []
    for (let item of shoppingList) {
        let amounts: string[] = []
        for (let [unit, amount] of item.amounts) {
            amounts.push(amount + " " + unit)
        }
        switch (format) {
            case "1":
                lines.push(textToBold(item.name) + " - " + amounts.join(", "))
                break
            case "2":
                lines.push("**" + item.name + "** - " + amounts.join(", "))
                break;
            default:
                lines.push(item.name + " - " + amounts.join(", "))
        }

    }
    return lines.join("\n")
}

export const ShoppingListDialog: FunctionComponent<ShoppingListDialogProps>
    = ({ shoppingList, onClose }) => {

        const [formatType, setFormatType] = useState("0")
        const [alert, showAlert] = useState(false)
        const shoppingListText = getShoppingListText(shoppingList, formatType)

        if (alert) {
            setTimeout(() => {
                showAlert(false)
            }, 2000)
        }

        return (
            <Modal show={true} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Shopping list</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Alert
                        variant="success"
                        show={alert}>
                        Shopping list has been copied to clipboard!
                    </Alert>

                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Output format</Form.Label>
                        <Form.Control size="sm" as="select" value={formatType} onChange={(e) => setFormatType(e.currentTarget.value)}>
                            <option value="0">None</option>
                            <option value="1">Unicode bold</option>
                            <option value="2">Markdown</option>
                        </Form.Control>
                    </Form.Group>
                    <Form>
                        <Form.Control
                            as="textarea"
                            rows={15}
                            cols={80}
                            value={shoppingListText}
                            readOnly />
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <CopyToClipboard text={shoppingListText} onCopy={() => showAlert(true)}>
                        <Button variant="danger">
                            Copy to clipboard
                        </Button>
                    </CopyToClipboard>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
