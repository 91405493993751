import React, { FunctionComponent } from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';
import { Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trash, Eye, People } from 'react-bootstrap-icons';

import { RecipeQuickView } from './RecipeQuickView'

import { RecipeStore } from "../store/RecipeStore";
import { Recipe } from "../model/Recipe";
import { Plan, PlannedRecipe } from "../model/Plan";

import { useDrop, useDrag } from 'react-dnd'
import _ from 'lodash';

type RecipeBoxProps = {
    bucketId: number,
    recipeStore: RecipeStore,
    plannedRecipe: PlannedRecipe,
    onDelete: (recipeId: string) => void
    onUpdate: (plannedRecipe: PlannedRecipe) => void
}

const RecipeBox: FunctionComponent<RecipeBoxProps> = ({
    bucketId,
    recipeStore,
    plannedRecipe,
    onDelete,
    onUpdate }) => {

    let recipe = recipeStore.get(plannedRecipe.getRecipeId()) ?? Recipe.error

    const [{ isDragging }, drag] = useDrag({
        item: { type: "recipe", plannedRecipe: plannedRecipe, from: bucketId },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    })

    const updateServing = (e: React.WheelEvent<HTMLDivElement>) => {
        var newServing = e.deltaY > 0
            ? Math.max(1, plannedRecipe.getServing() - 1)
            : Math.min(9, plannedRecipe.getServing() + 1);

        onUpdate(new PlannedRecipe(plannedRecipe.getRecipeId(), newServing))
    }

    const c = new Intl.Collator();
    const labels = recipe.getLabels()
        .sort(c.compare)
        .map(n => <Col key={n} sm="auto" className="mealee-bucker-recipe-label mt-1 ml-2">{n}</Col>)

    return (
        <div
            ref={drag}
            className="hover"
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
            }}>

            <Card className="mealee-bucket-recipe">
                <Card.Body className="mealee-bucket-recipe-body">
                    <Container>
                        <Row>
                            <Col className="pl-0 ml-2">
                                {recipe.getTitle()}
                            </Col>
                        </Row>
                        <Row>
                            {labels}
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            <div
                className="hover-on"
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: 40,
                    width: 20,
                    zIndex: 1,
                    opacity: 0.5
                }}>

                <Trash height="18" onClick={() => onDelete(recipe.getId())} style={{ cursor: 'pointer' }} />
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Popover id="popover-recipe-quick-view" className="recipe-quick-view">
                            <Popover.Title as="h4">{recipe.getTitle()}</Popover.Title>
                            <Popover.Content>
                                <RecipeQuickView recipe={recipe} />
                            </Popover.Content>
                        </Popover>}>
                    <Eye height="18" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>

            </div>

            <div
                className="hover-on"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 3,
                    height: 20,
                    width: 25,
                    zIndex: 1,
                    opacity: 0.5
                }}>

                <OverlayTrigger
                    key="new"
                    placement="right"
                    overlay={
                        <Tooltip id="tooltip-new">
                            Scroll up/down to change
                        </Tooltip>
                    }>
                    <div onWheel={updateServing}>
                        <span style={{ fontSize: 12, paddingTop: 2, paddingRight: 0 }}>{plannedRecipe.getServing()}</span>
                        <People height="12" />
                    </div>
                </OverlayTrigger>

            </div>
        </div>
    )
}

type BucketProps = {
    bucketId: number,
    recipeStore: RecipeStore,
    plannedRecipes: PlannedRecipe[],
    onDelete: (bucketid: number, recipeStore: string) => void,
    onUpdate: (bucketid: number, plannedRecipe: PlannedRecipe) => void,
}

const Bucket: FunctionComponent<BucketProps> = ({
    bucketId,
    recipeStore,
    plannedRecipes,
    onDelete,
    onUpdate }) => {

    return (
        <Card className="mealee-bucket">
            <Card.Header className="mealee-bucket-header">{"Day " + (bucketId + 1)}</Card.Header>
            <Card.Body className="mealee-bucket-body">
                {plannedRecipes.map(r =>
                    <RecipeBox key={r.getRecipeId()}
                        bucketId={bucketId}
                        recipeStore={recipeStore}
                        plannedRecipe={r}
                        onDelete={(recipeId) => onDelete(bucketId, recipeId)}
                        onUpdate={(plannedRecipe) => onUpdate(bucketId, plannedRecipe)} />)}
            </Card.Body>
        </Card>
    )
}

type DroppableBucketProps = BucketProps & {
    onDrop: (buckedId: number, plannedRecipe: PlannedRecipe) => void,
    onMove: (from: number, to: number, plannedRecipe: PlannedRecipe) => void
}

const DroppableBucket: FunctionComponent<DroppableBucketProps> = ({
    bucketId,
    recipeStore,
    plannedRecipes,
    onDrop,
    onMove,
    onDelete,
    onUpdate }) => {

    const [{ isOver }, drop] = useDrop({
        accept: "recipe",
        drop: (dragr) => {
            let r = dragr as unknown as { id: string, from?: number, plannedRecipe?: PlannedRecipe };
            if (r.from !== undefined && r.plannedRecipe !== undefined) {
                onMove(r.from, bucketId, r.plannedRecipe)
            }
            else {
                let recipe = recipeStore.get(r.id)
                onDrop(bucketId, new PlannedRecipe(r.id, recipe ? recipe.getServing() : 0))
            }
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    })

    return (
        <div
            ref={drop}
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
            }}>

            <Bucket
                bucketId={bucketId}
                recipeStore={recipeStore}
                plannedRecipes={plannedRecipes}
                onDelete={onDelete}
                onUpdate={onUpdate} />

            {isOver && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        zIndex: 1,
                        opacity: 0.5,
                        backgroundColor: 'yellow',
                    }} />
            )}

        </div>
    )
}

type PlannerProps = {
    recipeStore: RecipeStore,
    plan: Plan,
    updatePlan: (plan: Plan) => void
}

export const Planner: FunctionComponent<PlannerProps> = ({ recipeStore, plan, updatePlan }) => {

    const addRecipe = (bucketIdx: number, plannedRecipe: PlannedRecipe) => {
        updatePlan(plan.setPlannedRecipe(bucketIdx, plannedRecipe))
    }

    const deleteRecipe = (bucketIdx: number, recipeId: string) => {
        updatePlan(plan.deleteRecipeById(bucketIdx, recipeId))
    }

    const updatePlannedRecipe = (bucketIdx: number, plannedRecipe: PlannedRecipe) => {
        updatePlan(plan.setPlannedRecipe(bucketIdx, plannedRecipe))
    }

    const movePlannedRecipe = (from: number, to: number, plannedRecipe: PlannedRecipe) => {
        updatePlan(plan.deleteRecipeById(from, plannedRecipe.getRecipeId()).setPlannedRecipe(to, plannedRecipe))
    }

    let cols = _.range(plan.getNrBuckets()).map(i => {
        return (
            <Col key={i} xs="auto" className="pr-2 pl-0">
                <DroppableBucket
                    bucketId={i}
                    recipeStore={recipeStore}
                    plannedRecipes={plan.getPlannedRecipes(i)}
                    onDrop={addRecipe}
                    onDelete={deleteRecipe}
                    onUpdate={updatePlannedRecipe}
                    onMove={movePlannedRecipe} />
            </Col>
        )
    })

    return (
        <Container className="pt-0 pb-2 pl-0 pr-0">
            <Row>
                {cols}
            </Row>
        </Container>
    );

}
