import { ScoringFun, ScoringFunJSON } from './ScoringFun'

export interface SettingsJSON {
    nrBuckets: number;
    scoringFuns: ScoringFunJSON[];
}

export class SettingsBuilder {
    private json: SettingsJSON

    constructor(settings?: Settings) {
        this.json = settings ? settings.toJSON() : {} as SettingsJSON
    }

    buckets(nrBuckets: number): SettingsBuilder {
        this.json.nrBuckets = nrBuckets
        return this
    }

    scoringFuns(scoringfuns: ScoringFunJSON[]) {
        this.json.scoringFuns = scoringfuns
        return this
    }

    build(): Settings {
        return Settings.fromJSON(this.json)
    }
}

export class Settings {
    constructor() {
        this.nrBuckets = 7
        this.scoringFuns = []
    }

    static builder(settings?: Settings): SettingsBuilder {
        return new SettingsBuilder(settings)
    }

    public toJSON(): SettingsJSON {
        return {
            nrBuckets: this.nrBuckets,
            scoringFuns: this.scoringFuns.map(f => f.toJSON())
        };
    }

    public static fromJSON(o: SettingsJSON): Settings {
        if (!o.nrBuckets) {
            o.nrBuckets = 7
        }

        if (o.nrBuckets < 1 || o.nrBuckets > 7) {
            throw new Error("Wrong number of recipes")
        }

        if (!o.scoringFuns) {
            o.scoringFuns = []
        }

        let s = new Settings()
        s.nrBuckets = o.nrBuckets
        s.scoringFuns = o.scoringFuns.map(f => ScoringFun.fromJSON(f))

        return s
    }

    public getNrBuckets() {
        return this.nrBuckets
    }

    public getScoringFuns() {
        return this.scoringFuns
    }

    private nrBuckets: number
    private scoringFuns: ScoringFun[]
}
