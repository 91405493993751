import { List, Map, Set } from "immutable";

export class PlannedRecipe {

  constructor(recipeId: string, serving: number) {
    this.recipeId = recipeId;
    this.serving = serving;
  }

  public getRecipeId() {
    return this.recipeId;
  }

  /* get actual serving */
  public getServing() {
    return this.serving;
  }

  private recipeId: string;
  private serving: number;
}

/* IMMUTABLE */
export class Plan {

  // Don't store Recipes, only ids. They are immutable, so if they change...
  private buckets = List<Map<string, PlannedRecipe>>();

  private constructor(buckets: List<Map<string, PlannedRecipe>>) {
    this.buckets = buckets;
  }

  public static new = (nrBuckets: number) => {
    return new Plan(List().setSize(nrBuckets));
  };

  public setNrBuckets(nrBuckets: number) {
    return new Plan(this.buckets.setSize(nrBuckets));
  }

  public getNrBuckets() {
    return this.buckets.size;
  }

  public getNrRecipes() {
    let ret = 0;
    for (let bucket of this.buckets) {
      if (bucket) {
        ret += bucket.size
      }
    }
    return ret;
  }

  public setPlannedRecipe(bucketNr: number, plannedRecipe: PlannedRecipe) {
    let bucket = this.buckets.get(bucketNr) ?? Map();
    return new Plan(this.buckets.set(bucketNr, bucket.set(plannedRecipe.getRecipeId(), plannedRecipe)));
  }

  public deleteRecipeById(bucketNr: number, recipeId: string) {
    let bucket = this.buckets.get(bucketNr) ?? Map();
    return new Plan(this.buckets.set(bucketNr, bucket.delete(recipeId)));
  }

  public getPlannedRecipes(bucketNr: number) {
    let bucket = this.buckets.get(bucketNr) ?? Map();
    return Array.from(bucket.valueSeq());
  }

  public getAllPlannedRecipes() {
    let ids = List<PlannedRecipe>();
    for (let bucket of this.buckets) {
      if (bucket) {
        ids = ids.concat(bucket.values());
      }
    }
    return Array.from(ids);
  }

  /* A unique list of recipe ids */
  public getAllRecipeIds() {
    let ids = Set<string>();
    for (let bucket of this.buckets) {
      if (bucket) {
        ids = ids.concat(bucket.valueSeq().map(pr => pr.getRecipeId()));
      }
    }
    return Array.from(ids);
  }
}
