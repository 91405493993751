import React, {FunctionComponent} from 'react';

import {Modal, Form, Button} from 'react-bootstrap';

import download from 'downloadjs'

type SaveFileDialogProps = {
    title: string,
    saveButtonTitle: string,
    data: Blob,
    type: string,
    initialFileName: string,
    extension: string,
    onClose: () => void,
    onSave: () => void
}

export const SaveFileDialog: FunctionComponent<SaveFileDialogProps> 
                = ({title, saveButtonTitle, onClose, onSave, data, type, initialFileName, extension }) => {

    const [fileName, setFileName] = React.useState(initialFileName);

    const startDownload = () => {
        download(data, fileName+"."+extension, type)
    }

    return (
        <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
        <Form.Group controlId="formBasicFileName">
        <Form.Label>File name</Form.Label>
        <Form.Control type="fileName" value={fileName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFileName(e.target.value)}/>
        </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" variant="secondary" onClick={onClose}>Close</Button>
            <Button type="button" variant="primary" disabled={!fileName || fileName.length===0} 
                        onClick={() => {startDownload(); onSave()}}>{saveButtonTitle}</Button>
        </Modal.Footer>   
        </Modal>
    );
}