import React from "react";

import { LoadFileDialog } from "./component/LoadFileDialog";
import { SaveFileDialog } from "./component/SaveFileDialog";
import { SettingsDialog } from "./component/Settings";
import { RecipeEditor } from "./component/RecipeEditor";
import { ShoppingListDialog } from "./component/ShoppingListDialog";

import "./service/PlanExporterService";

import App from "./App";

export enum Dialog {
    LoadFile,
    SaveFile,
    RecipeEditor,
    SavePlanXLS,
    ShoppingList,
    PlanSettings
}

export function renderDialog(this: App) {
    switch (this.state.showDialog) {

        case Dialog.LoadFile: {
            return (
                <LoadFileDialog
                    onClose={this.closeDialog}
                    title="Load recipes file"
                    loadButtonTitle="Load recipes"
                    onLoad={this.loadFile}
                />
            );
        }

        case Dialog.SaveFile: {
            return (
                <SaveFileDialog
                    onClose={this.closeDialog}
                    title="Save recipes file"
                    saveButtonTitle="Save recipes"
                    data={this.genRecipeFileContent()}
                    type="application/json"
                    initialFileName="recipes"
                    extension="json"
                    onSave={this.fileSaved}
                />
            );
        }

        case Dialog.RecipeEditor: {
            return (
                <RecipeEditor
                    onClose={this.closeRecipeEditor}
                    onSave={this.saveRecipe}
                    onDelete={this.deleteRecipe}
                    recipe={this.state.recipeToEdit}
                    recipeStore={this.state.recipeStore}
                />
            );
        }

        case Dialog.SavePlanXLS: {
            return (
                <SaveFileDialog
                    onClose={this.closeDialog}
                    title="Save plan"
                    saveButtonTitle="Save plan"
                    data={this.state.plan.export(this.state.recipeStore)}
                    type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    initialFileName="plan"
                    extension="xlsx"
                    onSave={this.closeDialog}
                />
            );
        }

        case Dialog.ShoppingList: {
            return (
                <ShoppingListDialog
                    shoppingList={this.state.plan.exportShoppingList(this.state.recipeStore)}
                    onClose={this.closeDialog}
                />
            );
        }

        case Dialog.PlanSettings: {
            return (
                <SettingsDialog
                    onClose={this.closeDialog}
                    settings={this.state.settings}
                    onSave={this.updateSettings} />
            );
        }

        default:
            return <></>
    }
};
