import React, { FunctionComponent } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import {
    Upload, Download, PlusCircle, InfoCircle, Gear,
    CloudSlash, CloudCheck, CloudUpload
} from 'react-bootstrap-icons';

import { InfoBox } from "./InfoBox";
import { WorkspaceInfo } from "../model/WorkspaceInfo";
import { CloudProvider } from "../cloud/CloudProvider";

type IconBarProps = {
    onLoadFile: () => void;
    onNewRecipe: () => void;
    onSaveFile: () => void;
    onEditSettings: () => void;
    onCloudConnect: () => void;
    onCloudUpload: () => void;
    unsavedChanges: boolean;
    wsInfo: WorkspaceInfo;
    cloudProvider: CloudProvider;
};

export const IconBar: FunctionComponent<IconBarProps> = ({
    onLoadFile,
    onNewRecipe,
    onSaveFile,
    onEditSettings,
    onCloudConnect,
    onCloudUpload,
    unsavedChanges,
    wsInfo,
    cloudProvider
}) => {
    return (
        <Container className="pl-0 ml-2 mr-2 p-0">
            <Row className="mb-4">
                <OverlayTrigger
                    key="cloud-connect"
                    placement="right"
                    overlay={
                        <Tooltip id="tooltip-cloud-connect">
                            {cloudProvider.isSignedIn()
                                ? (unsavedChanges ? "Upload to Google Drive" : "Connected to Google Drive")
                                : "Connect to Google Drive"}
                        </Tooltip>
                    }>
                    <Col className="pr-0">
                        {cloudProvider.isSignedIn() ?
                            (unsavedChanges
                                ? <CloudUpload
                                    className="text-warning"
                                    style={{ cursor: "pointer" }}
                                    onClick={onCloudUpload} />
                                : <CloudCheck
                                    style={{ color: "#ffffff" }} />)
                            :
                            <CloudSlash
                                style={{ color: "#ffffff", cursor: "pointer" }}
                                onClick={onCloudConnect} />
                        }
                    </Col>
                </OverlayTrigger>
            </Row>
            <Row className="mb-4">
                <Col className="pr-0">
                    <OverlayTrigger
                        key="upload"
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-upload">
                                Load recipes and settings
                            </Tooltip>
                        }>
                        <Upload
                            className="my-first-step-a"
                            style={{ color: "#ffffff", cursor: "pointer" }}
                            onClick={onLoadFile} />
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col className="pr-0">
                    <OverlayTrigger
                        key="download"
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-download">
                                Download recipes and settings
                            </Tooltip>
                        }>
                        {cloudProvider.isSignedIn()
                            ? <Download
                                style={{ color: "#ffffff", cursor: "pointer" }}
                                onClick={onSaveFile} />
                            :
                            (unsavedChanges
                                ? <Download
                                    className="text-warning"
                                    style={{ cursor: "pointer" }}
                                    onClick={onSaveFile} />
                                : <Download
                                    style={{ color: "#ffffff40" }} />
                            )}
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col className="pr-0">
                    <OverlayTrigger
                        key="new"
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-new">
                                Add new recipe
                            </Tooltip>
                        }>
                        <PlusCircle
                            className="my-first-step-b"
                            style={{ color: "#ffffff", cursor: "pointer" }}
                            onClick={onNewRecipe} />
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col className="pr-0">
                    <OverlayTrigger
                        key="info"
                        placement="right"
                        overlay={
                            <Popover id="popover-info" className="quick-info">
                                <Popover.Title>Recipes information</Popover.Title>
                                <Popover.Content>
                                    <InfoBox wsInfo={wsInfo} />
                                </Popover.Content>
                            </Popover>
                        }>
                        <InfoCircle
                            style={{ color: "#ffffff", cursor: "pointer" }} />
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col className="pr-0">
                    <OverlayTrigger
                        key="settings"
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-settings">
                                Settings
                            </Tooltip>
                        }>

                        <Gear style={{
                            color: "#ffffff",
                            cursor: "pointer"
                        }} onClick={onEditSettings} />
                    </OverlayTrigger>
                </Col>
            </Row>
        </Container>
    );
};
