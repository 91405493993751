import React, { FunctionComponent, ChangeEvent } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

import CreatableSelect from 'react-select/creatable';

import { RecipeJSON, Recipe, Ingredient } from '../model/Recipe';
import { RecipeStore } from '../store/RecipeStore';
import '../service/RecipeService';

import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/selection/active-line.js';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/display/placeholder.js';

import { autocomplete, parseIngredients } from './IngredientLang'

type RecipeEditorProps = {
    recipe?: Recipe,
    recipeStore: RecipeStore,
    onClose: () => void,
    onSave: (recipe: Recipe) => void,
    onDelete: (recipe: Recipe) => void
}

export const RecipeEditor: FunctionComponent<RecipeEditorProps> = (props) => {

    const [recipeJSON, setRecipeJSON] = React.useState<RecipeJSON>(
        props.recipe ? props.recipe.toJSON() : Object.create({}));
    const [isTitleInvalid, setIsTitleInvalid] = React.useState(!props.recipe);
    const [isIngredientsInvalid, setIsIngredientsInvalid] = React.useState(false);

    const generateIngredientCSV = (ingredients: Ingredient[]) => {
        return ingredients.map(i => i.getName() + ";" + i.getAmount() + ";" + i.getUnit()).join("\n")
    }

    const [ingredientCSV, setIngredientCSV] = React.useState(props.recipe ?
        generateIngredientCSV(props.recipe.getIngredients()) : "");

    const allLabels = props.recipeStore.getLabels().map(
        l => { return { value: l, label: l } })

    const labels = recipeJSON.labels
        ? recipeJSON.labels.map(
            l => { return { value: l.toUpperCase(), label: l.toUpperCase() } })
        : []

    const setTitle = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal = e.currentTarget.value;
        setIsTitleInvalid(!newVal || newVal.length === 0);
        setRecipeJSON(r => ({ ...r, ...{ title: newVal } }))
    }

    const setImageURL = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal = e.currentTarget.value;
        setRecipeJSON(r => ({ ...r, ...{ imageURL: newVal } }))
    }

    const setComment = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal: string = e.currentTarget.value;
        setRecipeJSON(r => ({ ...r, ...{ comment: newVal } }))
    }

    const setSource = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal: string = e.currentTarget.value;
        setRecipeJSON(r => ({ ...r, ...{ source: newVal } }))
    }

    const setServing = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal: number | undefined = Number.parseInt(e.currentTarget.value)
        if (isNaN(newVal)) newVal = undefined
        setRecipeJSON(r => ({ ...r, ...{ serving: newVal } }))
    }

    const updateLabels = (newValue: any) => {
        let labels = (newValue as { label: string }[]).map(o => o.label)
        setRecipeJSON(r => ({ ...r, ...{ labels: labels } }))
    }

    const updateIngredients = (text: string) => {

        setIngredientCSV(text);

        let errors: CodeMirror.Annotation[] = [];
        let ingredients = parseIngredients(text, errors);
        let invalid = errors.length > 0;

        if (invalid) {
            setIsIngredientsInvalid(true);
        }
        else {
            setIsIngredientsInvalid(false);
            setRecipeJSON(r => ({ ...r, ...{ ingredients: ingredients } }))
        }
    }

    const saveRecipe = () => {
        props.onSave(Recipe.fromJSON(recipeJSON))
    }

    const deleteRecipe = () => {
        if (props.recipe) {
            props.onDelete(props.recipe)
        }
    }

    const focusElement = React.createRef<HTMLInputElement>();
    const ingredientEditorElement = React.createRef<HTMLTextAreaElement>();

    return (
        <Modal show={true} onHide={props.onClose} onEntered={() => {
            if (focusElement.current) focusElement.current.focus();
            if (ingredientEditorElement.current) {
                let cm = CodeMirror.fromTextArea(ingredientEditorElement.current,
                    {
                        lineNumbers: false,
                        mode: "ingredients",
                        gutters: ["CodeMirror-lint-markers"],
                        lint: true,
                        extraKeys: { "Ctrl-Space": "autocomplete" }
                    });

                cm.on("change", (instance: CodeMirror.Editor) => {
                    updateIngredients(instance.getValue());
                });
                cm.on("cursorActivity", (instance: CodeMirror.Editor) => {
                    instance.showHint({
                        hint: autocomplete(props.recipeStore.getIngredients(), props.recipeStore.getUnits()),
                        completeSingle: false,
                        closeOnUnfocus: true
                    });
                });
            }
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{props.recipe ? "Edit" : "Add new "} recipe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formTitle">
                        <Form.Control type="title" value={recipeJSON.title} placeholder="Title"
                            isInvalid={isTitleInvalid} onChange={setTitle}
                            ref={focusElement}
                            size="sm" />
                        <Form.Control.Feedback type="invalid">Required field!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formImageURL">
                        <Form.Control type="imageURL" value={recipeJSON.imageURL} placeholder="Image URL"
                            onChange={setImageURL}
                            size="sm" />
                        <Form.Control.Feedback type="invalid">Required field!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formComment">
                        <Form.Control
                            as="textarea"
                            value={recipeJSON.comment}
                            onChange={setComment}
                            rows={2}
                            placeholder="Comment"
                            size="sm" />
                    </Form.Group>
                    <Form.Group controlId="formSource">
                        <Form.Control
                            type="source"
                            value={recipeJSON.source}
                            onChange={setSource}
                            placeholder="Where to find the recipe"
                            size="sm" />
                    </Form.Group>
                    <Form.Group controlId="formServing">
                        <Form.Control
                            type="serving"
                            value={recipeJSON.serving}
                            onChange={setServing}
                            placeholder="Number of porties"
                            size="sm" />
                    </Form.Group>
                    <Form.Group controlId="formLabel">
                        <CreatableSelect
                            styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            size="sm"
                            options={allLabels}
                            isMulti
                            placeholder="Labels"
                            onChange={updateLabels}
                            value={labels} />
                    </Form.Group>
                    <Form.Group controlId="formIngredients">
                        <Form.Control as="textarea" value={ingredientCSV} ref={ingredientEditorElement}
                            placeholder="Ingredients: <name>;<amount>;<unit>" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="secondary" onClick={props.onClose}>Close</Button>
                <Button type="button" variant="primary" onClick={saveRecipe} disabled={isTitleInvalid || isIngredientsInvalid}>Save recipe</Button>
                {props.recipe &&
                    <Button type="button" variant="primary" onClick={deleteRecipe}>Delete recipe</Button>
                }
            </Modal.Footer>
        </Modal >)
}
