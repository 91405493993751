import React, { FunctionComponent } from 'react';

import { Modal, Form, Button, Tabs, Tab, Container, Table } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { Settings } from '../model/Settings';

import _ from 'lodash';

type SettingsDialogProps = {
    settings: Settings,
    onClose: () => void,
    onSave: (planSettings: Settings) => void
}

export const SettingsDialog: FunctionComponent<SettingsDialogProps> = (props) => {

    const [settingsChanged, setSettingsChanged] = React.useState(false);

    const [settingsJSON, setSettingsJSON] =
        React.useState(props.settings.toJSON());

    const changeNrRecipes = (newNrBuckets: number) => {
        let s = Object.assign({}, settingsJSON)
        s.nrBuckets = newNrBuckets
        setSettingsJSON(s)
        setSettingsChanged(true)
    }

    const addEmptyScoringFun = () => {
        let s = Object.assign({}, settingsJSON)
        s.scoringFuns = s.scoringFuns.concat([{ name: "new scoring fun", script: "random(1)" }])
        setSettingsJSON(s)
        setSettingsChanged(true)
    }

    const deleteScoringFun = (idx: number) => {
        let s = Object.assign({}, settingsJSON)
        s.scoringFuns = _.concat(_.take(s.scoringFuns, idx), _.drop(s.scoringFuns, idx + 1))
        setSettingsJSON(s)
        setSettingsChanged(true)
    }

    const changeName = (idx: number, newName: string) => {
        let s = Object.assign({}, settingsJSON)
        s.scoringFuns[idx].name = newName
        setSettingsJSON(s)
        setSettingsChanged(true)
    }

    const changeScript = (idx: number, newScript: string) => {
        let s = Object.assign({}, settingsJSON)
        s.scoringFuns[idx].script = newScript
        setSettingsJSON(s)
        setSettingsChanged(true)
    }

    const isValidName = (idx: number) => {
        return settingsJSON.scoringFuns[idx].name.trim().length > 0
    }

    const isValidScript = (idx: number) => {
        return settingsJSON.scoringFuns[idx].script.trim().length > 0
    }

    const scoringFunRows = settingsJSON.scoringFuns.map((f, idx) => {
        return (
            <tr>
                <td style={{ width: 150 }}>
                    <Form.Control
                        isInvalid={!isValidName(idx)}
                        className="mealee-table-edit"
                        type="name"
                        size="sm"
                        value={f.name}
                        onChange={e => changeName(idx, e.currentTarget.value)} />
                </td>
                <td>
                    <Form.Control
                        isInvalid={!isValidScript(idx)}
                        rows={isValidScript(idx) ? f.script.split(/\r\n|\r|\n/).length + 1 : 1}
                        as="textarea"
                        className="mealee-table-edit"
                        type="name"
                        size="sm"
                        style={{ height: "100%" }}
                        value={f.script}
                        onChange={e => changeScript(idx, e.currentTarget.value)} />
                </td>
                <td style={{ width: 30 }}>
                    <Trash
                        onClick={() => deleteScoringFun(idx)}
                        style={{ cursor: 'pointer' }} />
                </td>
            </tr>
        )
    })

    const saveSettings = () => {
        props.onSave(Settings.fromJSON(settingsJSON))
    }

    return (
        <Modal show={true} onHide={props.onClose} dialogClassName="mealee-settings-dialog">
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ width: 800 }}>
                <Tabs defaultActiveKey="general">
                    <Tab eventKey="general" title="General">
                        <Container className="p-3">
                            <Form onSubmit={(e) => e.preventDefault()}>
                                <Form.Group controlId="nrRecipes">
                                    <Form.Label>Number of recipes:</Form.Label>
                                    <Form.Control as="select" value={settingsJSON.nrBuckets}
                                        onChange={(e) => changeNrRecipes(parseInt(e.target.value))}>
                                        <option key="3">3</option>
                                        <option key="4">4</option>
                                        <option key="5">5</option>
                                        <option key="6">6</option>
                                        <option key="7">7</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Container>
                    </Tab>
                    <Tab eventKey="scoring" title="Scoring" className="h-100">
                        <Container className="p-3 h-100 d-flex flex-column">
                            <Form onSubmit={(e) => e.preventDefault()} className="h-100 d-grow-1">
                                <div className="p-0 tableFixHead">
                                    <Table hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Expression</th>
                                                <th className="m-0 p-0" style={{ width: 0 }}>&nbsp;</th>
                                            </tr>
                                        </thead><tbody>
                                            {scoringFunRows}
                                        </tbody>
                                    </Table>
                                </div>
                                <Button onClick={addEmptyScoringFun}>Add scoring function</Button>
                            </Form>
                        </Container>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="primary" onClick={saveSettings} disabled={!settingsChanged}>Save</Button>
                <Button type="button" variant="secondary" onClick={props.onClose}>Close</Button>
            </Modal.Footer>
        </Modal >
    );
}
