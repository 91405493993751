import { ScoringFun } from './ScoringFun';

/* These classes are not immutable, but it is fine as these are 
   not used in any state */

export class RecipeScoring {
    private scorings: Map<ScoringFun, number>
    private sum: number

    constructor() {
        this.scorings = new Map();
        this.sum = 0;
    }

    addScoring = (scoringFun: ScoringFun, score: number) => {
        this.scorings.set(scoringFun, score)
        this.sum += score
    }

    getSum = () => {
        return this.sum;
    }

    getScorings = () => {
        return Array.from(this.scorings);
    }
}

export class Scoring {
    private scoring: Map<string, RecipeScoring>
    private sum: number

    constructor() {
        this.scoring = new Map();
        this.sum = 0;
    }

    public addScoring = (recipeId: string, sf: ScoringFun, score: number) => {
        if (!this.scoring.has(recipeId)) {
            this.scoring.set(recipeId, new RecipeScoring())
        }

        this.scoring.get(recipeId)?.addScoring(sf, score);
    }

    public getSum = (recipeId: string) => {
        let rs = this.scoring.get(recipeId);
        if (rs) {
            return rs.getSum();
        }
        else {
            return 0;
        }
    }

    public getScoring = (recipeId: string) => {
        return this.scoring.get(recipeId);
    }
}
