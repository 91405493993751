import { RecipeStore } from '../store/RecipeStore';

import { Plan, PlannedRecipe } from '../model/Plan';
import { Recipe } from '../model/Recipe';

import XLSX from 'xlsx';
import _ from 'lodash';

export type ShoppingListItem = {
    name: string,
    amounts: Map<string, number>
}

declare module '../model/Plan' {
    interface Plan {
        exportShoppingList(recipeStore: RecipeStore): ShoppingListItem[]
        export(recipeStore: RecipeStore): Blob
    }
}

// Returns: Ingredient -> (recipe id -> [amount, unit, scale][])
function calculateIngredientMap(recipes: [Recipe, PlannedRecipe][]) {
    let ingredientMap = new Map<string, Map<PlannedRecipe, [number, string, number][]>>();

    recipes.forEach(t => {

        let r = t[0];
        let pr = t[1];

        r.getIngredients().forEach(i => {
            let ingredientName = i.getName().toLowerCase();
            let oneIngredientRecord = ingredientMap.get(ingredientName);
            if (!oneIngredientRecord) {
                oneIngredientRecord = new Map()
                ingredientMap.set(ingredientName, oneIngredientRecord);
            }

            let oneRecipeRecord = oneIngredientRecord.get(pr) ?? [];
            oneRecipeRecord.push([i.getAmount(), i.getUnit().toLowerCase(), pr.getServing() / r.getServing()]);
            oneIngredientRecord.set(pr, oneRecipeRecord);
        });
    });

    return ingredientMap;
}

function calculateShoppingList(
    ingredientMap: Map<string, Map<PlannedRecipe, [number, string, number][]>>,
    ingredients: string[]) {
    let ingredientList: ShoppingListItem[] = ingredients.map(name => {

        let oneIngredientRecord = new Map<string, number>()
        let ingredient = ingredientMap.get(name) ?? new Map<string, [number, string, number][]>()

        // Group ingredient usages by unit
        for (let oneUsage of ingredient.values()) {
            for (let [amount, unit, scale] of oneUsage) {
                oneIngredientRecord.set(unit,
                    (oneIngredientRecord.get(unit) ?? 0) + amount * scale)
            }
        }

        return { "name": name, "amounts": oneIngredientRecord }
    });

    return ingredientList;
}

// Exports ShoppingList as list of MD lines
Plan.prototype.exportShoppingList = function (recipeStore: RecipeStore) {
    // Wan't here the recipes as many times as they appear in the plan
    let plannedRecipes = this.getAllPlannedRecipes();
    let recipes = recipeStore.getAll(plannedRecipes.map(pr => pr.getRecipeId()));
    let ingredientMap = calculateIngredientMap(_.zip(recipes, plannedRecipes) as [Recipe, PlannedRecipe][]);

    let c = new Intl.Collator();
    let ingredients = Array.from(ingredientMap.keys()).sort(c.compare);
    let ShoppingList = calculateShoppingList(ingredientMap, ingredients);

    return ShoppingList;
}

// Exports Plan as XLSX
Plan.prototype.export = function (recipeStore: RecipeStore): Blob {
    // Want here the recipes as many times as they appear in the plan
    let plannedRecipes = this.getAllPlannedRecipes();
    let recipes = recipeStore.getAll(plannedRecipes.map(pr => pr.getRecipeId()));
    let zippedPlannedRecipes = _.zip(recipes, plannedRecipes) as [Recipe, PlannedRecipe][]

    let uniqueRecipes = new Set(recipes);
    let ingredientMap = calculateIngredientMap(zippedPlannedRecipes);
    let ingredients = Array.from(ingredientMap.keys()).sort();

    let wb = XLSX.utils.book_new();

    // ----------------------------------------------------------------------------------------
    // Generate map

    let planRows: string[][] = _.range(0, this.getNrBuckets()).map(bid => {
        let plannedRecipes = this.getPlannedRecipes(bid);
        let recipes = recipeStore.getAll(plannedRecipes.map(pr => pr.getRecipeId()));
        let zippedPlannedRecipes = _.zip(recipes, plannedRecipes) as [Recipe, PlannedRecipe][]
        return ["Day #" + (bid + 1)].concat(
            zippedPlannedRecipes.map(t => t[0].getTitle() + " (" + t[1].getServing() + " people)"))
    })
    let planWS = XLSX.utils.aoa_to_sheet(planRows);
    XLSX.utils.book_append_sheet(wb, planWS, "Plan");

    // ----------------------------------------------------------------------------------------
    // Calculate shopping list

    let shoppingList = calculateShoppingList(ingredientMap, ingredients);
    let ingredientRows = shoppingList.map((item) => {
        let row = []
        row.push(item.name)
        for (let unit of item.amounts.keys()) {
            row.push(item.amounts.get(unit))
            row.push(unit)
        }
        return row
    })
    let ingredientWS = XLSX.utils.aoa_to_sheet(ingredientRows);
    XLSX.utils.book_append_sheet(wb, ingredientWS, "Shopping list");

    // ----------------------------------------------------------------------------------------
    // Generate ingredient map

    const mapHeaderRow = zippedPlannedRecipes.map(t => t[0].getTitle() + " (" + t[1].getServing() + " people)")
    mapHeaderRow.unshift("")

    const mapDataRows = ingredients.map(name => {

        let line = [name]

        for (let pr of plannedRecipes) {
            let ingredient = ingredientMap.get(name) ?? new Map<PlannedRecipe, [number, string, number][]>();
            let recipeIngredient = ingredient.get(pr);
            if (recipeIngredient) {
                line.push(recipeIngredient.map(au => "" + (au[0] * au[2]) + " " + au[1]).join(", "));
            }
            else {
                line.push("");
            }

        }

        return line;
    });

    let mapRows = [mapHeaderRow].concat(mapDataRows);
    let mapWS = XLSX.utils.aoa_to_sheet(mapRows);
    XLSX.utils.book_append_sheet(wb, mapWS, "Ingredient map");

    // ----------------------------------------------------------------------------------------
    // Generate recipes

    // Recipes must be converted to a set to eliminate duplications
    for (let r of uniqueRecipes) {
        let recipeRows = [
            ["Title", r.getTitle()],
            ["Comment", r.getComment() ?? "-"],
            ["Serves", r.getServing()],
            ["Source", r.getSource() ?? "-"],
            ["Ingredients:"]
        ];

        for (let i of r.getIngredients()) {
            recipeRows.push([i.getName().toLowerCase(), i.getAmount(), i.getUnit().toLowerCase()])
        }

        let recipeWS = XLSX.utils.aoa_to_sheet(recipeRows);
        // Replace special characters not allowed in sheet name
        let sheetName = r.getTitle().substr(0, 31).replace(/[[\]/\\:*?]/g, '_');
        XLSX.utils.book_append_sheet(wb, recipeWS, sheetName);
    }

    let bytes = XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'array' });
    return new Blob([bytes], {
        type: 'application/octet-stream'
    });
}
