import React, { FunctionComponent, useEffect } from 'react';

import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Card, ListGroup } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { Image as BImage } from 'react-bootstrap';

import { Pencil, Eye, Search, X, Image as ImageIcon } from 'react-bootstrap-icons';

import { RecipeQuickView } from './RecipeQuickView'

import { Recipe } from '../model/Recipe';
import { Plan } from '../model/Plan';
import { Scoring, RecipeScoring } from '../model/Scoring';
import { RecipeStore } from '../store/RecipeStore';

import { useDrag } from 'react-dnd'

import { Set as ISet } from "immutable";
import _ from 'lodash';

type RecipeSelectorProps = {
    recipeStore: RecipeStore,
    scoring: Scoring,
    plan: Plan,
    highlightedRecipes: ISet<string>,
    onEdit: (recipe: Recipe) => void
}

type RecipeListItemProps = {
    recipe: Recipe,
    scoring?: RecipeScoring,
    selected: boolean,
    highlighted: boolean,
    onEdit: (recipe: Recipe) => void
}

const RecipeListItem = React.forwardRef<HTMLDivElement, RecipeListItemProps>(({
    recipe,
    selected,
    scoring,
    highlighted,
    onEdit }, ref) => {

    const [{ isDragging }, drag] = useDrag({
        item: { type: "recipe", id: recipe.getId() },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    })

    const c = new Intl.Collator();
    const labels = recipe.getLabels()
        .sort(c.compare)
        .map(n => <Col key={n} sm="auto" className="mealee-recipe-selector-label mt-1 ml-2">{n}</Col>)

    const imageURLExists = (recipe.getImageURL() || "").length > 0

    // Preload image so the size is known for positioning properly
    if (imageURLExists) {
        const img = new Image();
        img.src = recipe.getImageURL() as string;
    }

    return (
        <div
            ref={ref}
            className="hover"
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundColor: '#FFFFFF'
            }}>

            <Card
                className={highlighted ? "mealee-recipe-selector-item-highlighted" : "mealee-recipe-selector-item"}
                key={recipe.getId()}
                ref={drag}
                style={{
                    opacity: isDragging || selected ? 0.5 : 1,
                    cursor: 'move',
                }}>
                <Card.Body className="p-0">
                    <Container>
                        <Row>
                            <Col className="pl-0 ml-2">
                                {recipe.getTitle()}
                            </Col>
                        </Row>
                        <Row>
                            {labels}
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            <div
                className="hover-on"
                style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: 10,
                    height: 20,
                    width: imageURLExists ? 60 : 40,
                    zIndex: 1,
                    opacity: 0.5
                }}>

                {imageURLExists &&
                    <>
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <BImage src={recipe.getImageURL()} className="recipe-image rounded" />
                            }>
                            <ImageIcon height="18" style={{ cursor: 'pointer' }} />
                        </OverlayTrigger>
                        &nbsp;
                    </>}
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Popover id="popover-recipe-quick-view" className="recipe-quick-view">
                            <Popover.Title as="h4">{recipe.getTitle()}</Popover.Title>
                            <Popover.Content>
                                <RecipeQuickView recipe={recipe} scoring={scoring} />
                            </Popover.Content>
                        </Popover>}>
                    <Eye height="18" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
                &nbsp;
                <Pencil height="18" onClick={() => onEdit(recipe)} style={{ cursor: 'pointer' }} />

            </div>

        </div >
    )
})

export const RecipeSelector: FunctionComponent<RecipeSelectorProps> = ({
    recipeStore,
    scoring,
    plan,
    highlightedRecipes,
    onEdit }) => {

    const [filter, setFilter] = React.useState("");
    // Use this to remember if we already scrolled to the highlited element
    // We only want to do that once
    const [lastHighlightedRecipes, setLastHighlightedRecipes] = React.useState(ISet());

    const debouncedSetFilter = React.useCallback(
        (event) => _.debounce(setFilter, 100)(event),
        [], // will be created only once initially
    );

    const refresh = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSetFilter(event.currentTarget.value);
    }

    const filteredRecipes = (filter.trim().length === 0) ?
        recipeStore.all() : recipeStore.search(filter).map(res => res.key);

    const selectedRecipeSet = new Set(plan.getAllRecipeIds())

    let highlightedListItemRef: React.RefObject<HTMLDivElement> | null = null

    const listItems = _.sortBy(filteredRecipes, r => scoring.getSum(r.getId()) * -1)
        .map((recipe) => {

            const highlighted = highlightedRecipes.has(recipe.getId())
            const ref = highlighted && !highlightedListItemRef ? React.createRef<HTMLDivElement>() : null

            if (ref) {
                highlightedListItemRef = ref;
            }

            return (
                <ListGroup.Item className="p-0" key={recipe.getId()} >
                    <RecipeListItem
                        key={recipe.getId()}
                        ref={ref}
                        recipe={recipe}
                        scoring={scoring.getScoring(recipe.getId())}
                        onEdit={onEdit}
                        selected={selectedRecipeSet.has(recipe.getId())}
                        highlighted={highlighted} />
                </ListGroup.Item >
            )
        });

    // Scroll to first highlighted item, but only once
    // If highlightedRecipes === lastHighlightedRecipes, we already scrolled there
    useEffect(() => {
        if (highlightedListItemRef && highlightedRecipes !== lastHighlightedRecipes) {
            if (highlightedListItemRef.current) {
                highlightedListItemRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
                setLastHighlightedRecipes(highlightedRecipes);
            }
        }

    }, [highlightedListItemRef, highlightedRecipes, lastHighlightedRecipes]);

    return (
        <Form className="pt-2 h-100 d-flex flex-column" onSubmit={(e) => e.preventDefault()}>
            <InputGroup className="mb-1">
                <InputGroup.Prepend>
                    <InputGroup.Text><Search /></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="text" placeholder="Filter recipes" value={filter} onChange={refresh} />
                <InputGroup.Append>
                    <InputGroup.Text style={{ backgroundColor: "white" }}>
                        <OverlayTrigger
                            key="download"
                            placement="right"
                            overlay={
                                <Tooltip id="clear-search-field">
                                    Clear search field
                                </Tooltip>
                            }>
                            <X style={{ cursor: "pointer" }} onClick={() => setFilter("")} />
                        </OverlayTrigger>
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
            <Form.Group controlId="recipeGroup" className="flex-grow-1 overflow-auto mealee-recipe-selector-list">
                <ListGroup>{listItems}</ListGroup>
            </Form.Group>
        </Form>
    );
}
