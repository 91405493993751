export interface ScoringFunJSON {
    name: string
    script: string
}

/* IMMUTABLE */
export class ScoringFun {

    private name: string
    private script: string

    constructor(name: string, script: string) {
        this.name = name
        this.script = script
    }

    public toJSON(): ScoringFunJSON {
        return {
            name: this.name,
            script: this.script
        };
    }

    public static fromJSON(o: ScoringFunJSON): ScoringFun {
        if (!o.name) {
            throw new Error("Missing name field")
        }

        if (!o.script) {
            throw new Error("Missing script field")
        }

        return new ScoringFun(o.name, o.script)
    }

    public getName() {
        return this.name
    }

    public getScript() {
        return this.script
    }
}
