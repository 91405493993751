import React, {FunctionComponent} from 'react';

import {Modal, Form, FormControl, Button} from 'react-bootstrap';

type LoadFileDialogProps = {
    title: string,
    loadButtonTitle: string,
    onClose: () => void,
    onLoad: (file: File) => void
}

export const LoadFileDialog: FunctionComponent<LoadFileDialogProps> = ({title, loadButtonTitle, onClose, onLoad}) => {

    const [file, setFile] = React.useState<null | File>(null);

    return (
        <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
        <FormControl type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if(e.target.files)
                                                {
                                                    setFile(e.target.files[0])
                                                }
                                                else
                                                {
                                                    setFile(null)
                                                }
                                            }}/>
        </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" variant="secondary" onClick={onClose}>Close</Button>
            <Button type="button" variant="primary" disabled={!file} 
                        onClick={() => {if(file) onLoad(file)}}>{loadButtonTitle}</Button>
        </Modal.Footer>   
        </Modal>
    );
}