import React, { FunctionComponent } from 'react';
import { Container, Row, Col } from "react-bootstrap";

import { WorkspaceInfo } from '../model/WorkspaceInfo';

type InfoBoxProps = {
    wsInfo: WorkspaceInfo
}

export const InfoBox: FunctionComponent<InfoBoxProps> = ({ wsInfo }) => {

    return (
        <Container>
            <Row>
                <Col xs={6}>Last updated:</Col>
                <Col sm="auto">{wsInfo.lastUpdated ? wsInfo.lastUpdated.toLocaleString() : "-"}</Col>
            </Row>
            <Row>
                <Col xs={6}>Number of recipes:</Col>
                <Col sm="auto">{wsInfo.nrRecipes}</Col>
            </Row>
        </Container>
    )
}

