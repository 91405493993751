import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import App from './App'
import { GoogleDrive } from './cloud/GoogleDrive'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory
} from "react-router-dom"

import * as queryString from "query-string"

import { isMobile } from "react-device-detect"

import HttpsRedirect from 'react-https-redirect'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

const trackingId = "UA-74643376-3"
ReactGA.initialize(trackingId)
/*
ReactGA.set({
 any data that is relevant to the user session
 that you would like to track with google analytics
})
*/
ReactGA.pageview("/")

const AppRoot = () => {
    let location = useLocation()
    let history = useHistory()

    let cloudProvider = undefined
    let error = undefined

    if (location.state && location.state.cloudProvider === "googleDrive") {
        cloudProvider = GoogleDrive.withAccessToken(location.state.accessToken)
        error = location.state.error
    }

    // Delete state from history
    if (location.state)
        history.replace("/", undefined)

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <App cloudProvider={cloudProvider} error={error} />
        </DndProvider>
    )
}

const GoogleCallback = () => {
    let location = useLocation()
    let parsedHash = queryString.parse(location.hash)

    return (
        <Redirect to={{
            pathname: "/",
            state: {
                cloudProvider: "googleDrive",
                accessToken: parsedHash["access_token"],
                error: parsedHash["error"]
            }
        }} />
    )
}

ReactDOM.render(
    <React.StrictMode>
        <HttpsRedirect>
            <Router>
                <Switch>
                    <Route exact path={"/"}>
                        <AppRoot />
                    </Route>
                    <Route path={"/google-signin"}>
                        <GoogleCallback />
                    </Route>
                </Switch>
            </Router>
        </HttpsRedirect>
    </React.StrictMode>,
    document.getElementById('root')
)
