import CodeMirror from "codemirror";
import "codemirror/addon/hint/show-hint";

import { IngredientJSON } from '../model/Recipe';

CodeMirror.defineMode("ingredients", () => {
  var Location = {
    INGREDIENT: 0,
    AMOUNT: 1,
    UNIT: 2,
    ERROR: 3,
  };

  return {
    startState: function () {
      return {
        location: Location.INGREDIENT,
      };
    },
    token: function (stream, state) {
      if (stream.sol()) {
        state.location = Location.INGREDIENT;
      }

      var ch = stream.next();

      if (ch === ";") {
        if (state.location === Location.INGREDIENT)
          state.location = Location.AMOUNT;
        else if (state.location === Location.AMOUNT)
          state.location = Location.UNIT;
        else state.location = Location.ERROR;
      }

      if (state.location === Location.ERROR) {
        return "error";
      }

      if (ch === ";") {
        return "keyword";
      }

      if (state.location === Location.INGREDIENT) {
        stream.eatWhile(function (c: string) {
          if (c !== ";") {
            return true;
          }
          return false;
        });
        return "atom";
      }

      if (state.location === Location.AMOUNT) {
        stream.eatWhile(function (c: string) {
          if (c !== ";") {
            return true;
          }
          return false;
        });
        return "number";
      }

      if (state.location === Location.UNIT) {
        stream.eatWhile(function (c: string) {
          if (c !== ";") {
            return true;
          }
          return false;
        });
        return "atom";
      }

      return "error";
    },
  };
});

export function parseIngredients(text: string, errors: CodeMirror.Annotation[]) {

  let ingredients: IngredientJSON[] = [];

  let lines: string[] = text.split(/\r?\n/);

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];

    if (line.trim().length > 0) {
      let parts = line.split(";");

      if (parts.length < 3) {
        errors.push({
          from: CodeMirror.Pos(i, 0),
          to: CodeMirror.Pos(i, line.length),
          message: "Not enough parts. Syntax: <name>;<amount>;<unit>",
        });
      } else if (parts.length > 3) {
        let startpos = parts[0].length + parts[1].length + parts[2].length + 2;
        errors.push({
          from: CodeMirror.Pos(i, startpos),
          to: CodeMirror.Pos(i, line.length),
          message: "Too many parts. Syntax: <name>;<amount>;<unit>",
        });
      }
      else {
        let startAmount = parts[0].length + 1;
        let endAmount = startAmount + parts[1].length;

        parts = parts.map(s => s.trim());

        if (parts[0].length === 0 || parts[1].length === 0 || parts[2].length === 0) {
          errors.push({
            from: CodeMirror.Pos(i, 0),
            to: CodeMirror.Pos(i, line.length),
            message: "Empty part(s). Syntax: <name>;<amount>;<unit>",
          });
        }
        else if ("" + Number.parseFloat(parts[1]) !== parts[1]) {
          errors.push({
            from: CodeMirror.Pos(i, startAmount),
            to: CodeMirror.Pos(i, endAmount),
            message: "Amount should be a number.",
          });
        }
        else {
          ingredients.push({ name: parts[0], amount: Number.parseFloat(parts[1]), unit: parts[2] })
        }
      }
    }
  }

  return ingredients;
}

CodeMirror.registerHelper("lint", "ingredients", function (text: string) {
  let errors: CodeMirror.Annotation[] = [];
  parseIngredients(text, errors);
  return errors;
});

export function autocomplete(ingredients: string[], units: string[]) {
  return function (
    cm: CodeMirror.Editor,
  ) {

    var cursor = cm.getCursor(),
      line = cm.getLine(cursor.line);
    var start = cursor.ch,
      end = cursor.ch;

    let nrpart = (line.substring(0, end).match(/;/g) || []).length;

    if (nrpart !== 0 && nrpart !== 2) {
      return null;
    }

    while (start && line.charAt(start - 1) !== ";") --start;
    while (end < line.length && line.charAt(end) !== ";") ++end;
    var word = line.slice(start, end).trimLeft().toLowerCase();

    let selection =
      nrpart === 0
        ? ingredients.filter((i) => i.indexOf(word) >= 0)
        : units.filter((i) => i.startsWith(word));

    if(selection.length === 1 && selection[0] === word) {
        selection = []
    }

    return {
      list: selection,
      from: CodeMirror.Pos(cursor.line, start),
      to: CodeMirror.Pos(cursor.line, end),
    };
  }
};
