import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Alert } from "react-bootstrap";

import * as AppDialogs from "./AppDialogs";
import { Dialog } from "./AppDialogs";
import * as AppBodies from "./AppBodies";
import { AppState, defaultAppState, serializeAppState, deserializeAppState } from "./AppState";

import { CloudProvider } from "./cloud/CloudProvider"
import { GoogleDrive } from "./cloud/GoogleDrive"

import { Map } from "immutable";

import { Settings } from "./model/Settings";
import { Plan } from "./model/Plan";
import { Recipe } from "./model/Recipe";
import { Workspace, WorkspaceJSON } from "./model/Workspace";

import { deserializeWorkspace } from "./service/WorkspaceService";

import { IconBar } from "./component/IconBar";
import { MenuBar } from "./component/MenuBar";

import { Set as ISet } from "immutable";

import { Beforeunload } from "react-beforeunload";
import { isMobileOnly } from "react-device-detect";

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

import "bootstrap/dist/css/bootstrap.min.css";

type AppProps = {
    cloudProvider?: CloudProvider,
    error?: string
}

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        let storedState = window.sessionStorage.getItem('state');
        // Delete state after load
        window.sessionStorage.clear();

        let cloudProvider = props.cloudProvider ?? GoogleDrive.new()

        if (storedState) {
            this.state = deserializeAppState(JSON.parse(storedState),
                cloudProvider, props.error);
        }
        else {
            this.state = defaultAppState(cloudProvider, props.error);
        }
    }

    public renderDialog = AppDialogs.renderDialog;
    public renderInitialBody = AppBodies.renderInitialBody;
    public renderDesktopBody = AppBodies.renderDesktopBody;

    addRecipes = (newRecipes: Recipe[]) => {
        this.setState((st) => {
            let newRand = newRecipes.reduce((acc: Map<string, number>, r: Recipe) => {
                if (acc.has(r.getId())) {
                    return acc;
                } else {
                    return acc.set(r.getId(), Math.random());
                }
            }, st.rand)

            let ret = {};
            let newRecipeStore = st.recipeStore.addAll(newRecipes);

            if (newRecipeStore !== st.recipeStore) {
                ret = {
                    recipeStore: newRecipeStore,
                    rand: newRand
                };
            }

            return ret;
        });
    };

    setIsInitialTour = (t: boolean) => {
        this.setState({ isInitialTour: t });
    }

    saveRecipe = (recipe: Recipe) => {
        this.addRecipes([recipe]);
        this.setState({
            unsavedChanges: true,
            highlightedRecipes: ISet([recipe.getId()])
        });
        this.closeRecipeEditor();
    };

    loadWorkspace(data: any) {

        let json = (typeof data == "string" ? JSON.parse(data) : data) as WorkspaceJSON

        try {
            let ws = deserializeWorkspace(json);
            this.addRecipes(ws.getRecipes());
            this.updateSettings(ws.getSettings());
            this.setState({ lastUpdated: ws.getLastUpdated() });
        } catch (e) {
            this.setState({ error: e.message });
        }
    }

    fileLoaded = (ev: ProgressEvent<FileReader>) => {
        let data = ev.target?.result;
        this.loadWorkspace(data);
        if (this.state.cloudProvider.isSignedIn()) {
            this.setState({ unsavedChanges: true });
        }
        this.closeDialog();
    };

    loadFile = (file: File) => {
        var reader = new FileReader();
        reader.onload = (ev) => this.fileLoaded(ev);
        reader.readAsText(file, "UTF-8");
    };

    genRecipeFileContent = () => {
        let ws = new Workspace(this.state.recipeStore.all(), this.state.settings);
        return ws.toBlob();
    };

    workspaceSaved() {
        this.setState({ unsavedChanges: false, lastUpdated: new Date() });
    }

    fileSaved = () => {
        if (!this.state.cloudProvider.isSignedIn()) {
            this.workspaceSaved();
        }
        this.closeDialog();
    };

    showDialog = (dialog: Dialog) => {
        return () => this.setState({ showDialog: dialog });
    };

    closeDialog = () => {
        this.setState({ showDialog: undefined });
    };

    closeRecipeEditor = () => {
        this.setState({ recipeToEdit: undefined, showDialog: undefined });
    };

    dismissError = () => {
        this.setState({ error: undefined });
    };

    updateSettings = (ps: Settings) => {
        this.setState((st) => {
            return {
                settings: ps,
                plan: st.plan.setNrBuckets(ps.getNrBuckets()),
                showDialog: undefined
            };
        });
    };

    updatePlan = (plan: Plan) => {
        this.setState({
            plan: plan
        });
    };

    editRecipe = (recipe: Recipe) => {
        this.setState({ showDialog: Dialog.RecipeEditor, recipeToEdit: recipe });
    };

    deleteRecipe = (recipe: Recipe) => {
        this.setState((st) => {
            let newRecipeStore = st.recipeStore.delete(recipe.getId());

            return {
                recipeStore: newRecipeStore,
                showDialog: undefined,
                recipeToEdit: undefined,
                unsavedChanges: true,
            };
        });
    };

    connectToCloud = () => {
        let json = serializeAppState(this.state);
        window.sessionStorage.setItem("state", JSON.stringify(json));

        this.state.cloudProvider.signIn();
    };

    uploadToCloud = () => {
        this.setState({ loading: true });
        this.state.cloudProvider.upload(this.genRecipeFileContent())
            .then(() => { this.workspaceSaved(); })
            .catch((err: Error) => {
                this.setState({ error: err.message });
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    componentDidMount() {
        if (this.state.cloudProvider.isSignedIn()) {
            this.setState({ loading: true });
            this.state.cloudProvider.download()
                .then((data: WorkspaceJSON | null) => {
                    if (data) {
                        this.loadWorkspace(data)
                    }
                })
                .catch((err: Error) => {
                    this.setState({ error: err.message });
                })
                .finally(() => {
                    this.setState({ loading: false });
                })
        }
    }

    render(this: App) {

        const loaderCSS = css`
            position: fixed; /* or absolute */
            top: 50%;
            left: 50%;
            `;

        return (
            <Container fluid className="d-flex flex-column vh-100">
                <Alert
                    variant="danger"
                    onClose={this.dismissError}
                    show={!!this.state.error}
                    dismissible>
                    {this.state.error}
                </Alert>

                {this.state.unsavedChanges &&
                    <Beforeunload onBeforeunload={() => "You'll lose your data!"} />
                }

                {this.renderDialog()}

                <ClipLoader color={"#ffffff"} loading={this.state.loading} css={loaderCSS} size={100} />

                <Row>
                    <Col>
                        <MenuBar />
                    </Col>
                </Row>

                {!isMobileOnly
                    ?
                    <Row className="h-100" style={{ minHeight: 0 }}>
                        <Col xs="auto" className="pl-0 pr-2 pt-3">
                            <IconBar
                                onLoadFile={this.showDialog(Dialog.LoadFile)}
                                onNewRecipe={this.showDialog(Dialog.RecipeEditor)}
                                onSaveFile={this.showDialog(Dialog.SaveFile)}
                                onEditSettings={this.showDialog(Dialog.PlanSettings)}
                                onCloudConnect={this.connectToCloud}
                                onCloudUpload={this.uploadToCloud}
                                unsavedChanges={this.state.unsavedChanges}
                                wsInfo={{
                                    lastUpdated: this.state.lastUpdated,
                                    nrRecipes: this.state.recipeStore.count()
                                }}
                                cloudProvider={this.state.cloudProvider} />
                        </Col>
                        {this.state.recipeStore.count() === 0 && this.renderInitialBody()}
                        {this.state.recipeStore.count() > 0 && !isMobileOnly && this.renderDesktopBody()}
                    </Row>
                    :
                    <Row>
                        <Col className="mt-3">
                            <Alert key="recipe" variant="danger">
                                Mobile is not supported as for now. Please try it on a little bit bigger device. Sorry for that.
                            </Alert>
                        </Col>
                    </Row>
                }

            </Container >
        );
    }
}

export default App;
