export interface IngredientJSON {
    name: string;
    amount: number;
    unit: string;
}

export interface RecipeJSON {
    id: string;
    title: string;
    source?: string;
    comment?: string;
    serving?: number;
    ingredients: IngredientJSON[];
    labels?: string[];
    imageURL?: string;
}

/* IMMUTABLE */
export class Ingredient {
    private constructor(name: string, amount: number, unit: string) {
        this.name = name;
        this.amount = amount;
        this.unit = unit;
    }

    public getName(): string {
        return this.name;
    }

    public getAmount(): number {
        return this.amount;
    }

    public getUnit(): string {
        return this.unit;
    }

    public static fromJSON(o: IngredientJSON): Ingredient {
        if (!o.name) {
            throw new Error("Missing ingredient name");
        }

        if (!o.unit) {
            throw new Error("Missing ingredient unit");
        }

        if (o.amount === undefined) {
            throw new Error("Missing ingredient amount");
        }

        return new Ingredient(o.name, o.amount, o.unit);
    }

    public toJSON(): IngredientJSON {
        return {
            name: this.name,
            amount: this.amount,
            unit: this.unit,
        };
    }

    private name: string;
    private amount: number;
    private unit: string;
}

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/* IMMUTABLE */
export class Recipe {
    private constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
        this.ingredients = [];
        this.labels = [];
        this.serving = 4;
    }

    public static error = new Recipe("error", "Recipe cannot be found")

    public getId(): string {
        return this.id;
    }

    public getTitle(): string {
        return this.title;
    }

    public getComment(): string | undefined {
        return this.comment;
    }

    public getSource(): string | undefined {
        return this.source;
    }

    public getServing(): number {
        return this.serving;
    }

    public getIngredients(): Ingredient[] {
        return this.ingredients;
    }

    public getLabels(): string[] {
        return this.labels;
    }

    public getImageURL(): string | undefined {
        return this.imageURL;
    }

    public static fromJSON(o: RecipeJSON): Recipe {
        if (!o.id) {
            o.id = uuidv4();
        }

        if (!o.title) {
            throw new Error("Missing recipe title");
        }

        let r = new Recipe(o.id, o.title);

        r.comment = o.comment;
        r.source = o.source;
        r.serving = o.serving ?? 4;
        r.labels = o.labels ?? [];
        r.imageURL = o.imageURL;

        if (Array.isArray(o.ingredients)) {
            r.ingredients = o.ingredients.map((i) => Ingredient.fromJSON(i));
        }

        return r;
    }

    public toJSON(): RecipeJSON {
        return {
            id: this.id,
            title: this.title,
            comment: this.comment,
            source: this.source,
            serving: this.serving,
            ingredients: this.ingredients.map((i) => i.toJSON()),
            labels: this.labels,
            imageURL: this.imageURL
        };
    }

    private id: string;
    private title: string;
    private comment?: string;
    private source?: string;
    private serving: number;
    private ingredients: Ingredient[];
    private labels: string[];
    private imageURL?: string;
}
