import { RecipeStore } from "./store/RecipeStore";

import { Settings } from "./model/Settings";
import { Plan } from "./model/Plan";
import { Recipe } from "./model/Recipe";
import { Workspace, WorkspaceJSON } from "./model/Workspace";

import { Dialog } from "./AppDialogs";

import { Map as IMap, Set as ISet } from "immutable";

import { CloudProvider } from "./cloud/CloudProvider";

import "./service/WorkspaceService"
import { deserializeWorkspace } from "./service/WorkspaceService"

export type AppState = {
    cloudProvider: CloudProvider;
    recipeStore: RecipeStore;
    settings: Settings;
    plan: Plan;
    showDialog?: Dialog;
    recipeToEdit?: Recipe;
    error?: string;
    loading: boolean;
    unsavedChanges: boolean;
    isInitialTour: boolean;
    lastUpdated?: Date;
    rand: IMap<string, number>; // stable random numbers per recipe
    highlightedRecipes: ISet<string>;
};

interface AppStateJSON {
    workspace: WorkspaceJSON;
    unsavedChanges: boolean;
    isInitialTour: boolean;
    rand: { [key: string]: number };
    highlightedRecipes: string[];
}

export function defaultAppState(cloudProvider: CloudProvider, error?: string): AppState {
    let recipeStore = new RecipeStore();
    let planSettings = new Settings();
    let plan = Plan.new(planSettings.getNrBuckets());

    return {
        cloudProvider: cloudProvider,
        recipeStore: recipeStore,
        settings: planSettings,
        plan: plan,
        showDialog: undefined,
        recipeToEdit: undefined,
        error: error,
        loading: false,
        unsavedChanges: false,
        isInitialTour: false,
        rand: IMap(),
        highlightedRecipes: ISet()
    };
}

export function serializeAppState(st: AppState): AppStateJSON {
    let ws = new Workspace(st.recipeStore.all(), st.settings);
    let wsJSON = ws.serialize();

    return {
        workspace: wsJSON,
        unsavedChanges: st.unsavedChanges,
        isInitialTour: st.isInitialTour,
        rand: st.rand.toJSON(),
        highlightedRecipes: st.highlightedRecipes.toJSON()
    };
}

export function deserializeAppState(json: AppStateJSON, cloudProvider: CloudProvider, error?: string): AppState {
    let ws = deserializeWorkspace(json.workspace);
    let recipeStore = new RecipeStore();

    return {
        cloudProvider: cloudProvider,
        recipeStore: recipeStore.addAll(ws.getRecipes()),
        plan: Plan.new(ws.getSettings().getNrBuckets()),
        settings: ws.getSettings(),
        showDialog: undefined,
        recipeToEdit: undefined,
        error: error,
        loading: false,
        unsavedChanges: json.unsavedChanges ?? false,
        isInitialTour: json.isInitialTour ?? false,
        lastUpdated: ws.getLastUpdated(),
        rand: IMap(json.rand),
        highlightedRecipes: ISet(json.highlightedRecipes)
    }
}