
import { Recipe, RecipeJSON } from './Recipe'
import { Settings, SettingsJSON } from './Settings'

export interface WorkspaceJSON {
    fileVersion: number;
    recipes: RecipeJSON[];
    settings: SettingsJSON;
    lastUpdated: string;
}

export class Workspace {

    constructor(recipes: Recipe[], settings: Settings, lastUpdated?: Date) {
        this.recipes = recipes;
        this.settings = settings;
        this.lastUpdated = lastUpdated ?? new Date();
    }

    public getRecipes() {
        return this.recipes;
    }

    public getSettings() {
        return this.settings;
    }

    public getLastUpdated() {
        return this.lastUpdated;
    }

    private recipes: Recipe[];
    private settings: Settings;
    private lastUpdated: Date;
}