import { RecipeStore } from '../store/RecipeStore'

declare module '../store/RecipeStore' {
    interface RecipeStore {
        getIngredients(): string[],
        getUnits(): string[],
        getLabels(): string[]
    }
}

RecipeStore.prototype.getIngredients = function () {
    let ingredients = new Set<string>();

    this.all().forEach(r => {
        r.getIngredients().forEach(i => {
            ingredients.add(i.getName().toLowerCase());
        })
    });

    const c = new Intl.Collator();
    return Array.from(ingredients).sort(c.compare);
};

RecipeStore.prototype.getUnits = function () {

    let units = new Set<string>();

    this.all().forEach(r => {
        r.getIngredients().forEach(i => {
            units.add(i.getUnit().toLowerCase());
        })
    });

    const c = new Intl.Collator();
    return Array.from(units).sort(c.compare);
}

RecipeStore.prototype.getLabels = function () {

    let labels = new Set<string>();

    this.all().forEach(r => {
        r.getLabels().forEach(l => {
            labels.add(l.toUpperCase());
        })
    });

    const c = new Intl.Collator();
    return Array.from(labels).sort(c.compare);
}