import React from "react";

import { Alert, Button } from "react-bootstrap";
import { Container, Col, Row, Image } from "react-bootstrap";

import './service/ScoringService';

import { RecipeSelector } from "./component/RecipeSelector";
import { Planner } from "./component/Planner";

import Tour from 'reactour';

import App from "./App";
import { Dialog } from "./AppDialogs";

export function renderInitialBody(this: App) {
    return (
        <Col xs="auto" className="pt-3">
            <Tour
                isOpen={this.state.isInitialTour}
                onRequestClose={() => this.setIsInitialTour(false)}
                steps={[
                    {
                        selector: '.my-first-step-a',
                        content: <Container className="pt-4">Load some recipes from a file or ...</Container>,
                    },
                    {
                        selector: '.my-first-step-b',
                        content: <Container className="pt-4">... or add a new recipe manually!</Container>,
                    }
                ]} />
            <Alert key="recipe" variant="warning">
                I do not see any recipes. <Button variant="link" className="pt-1" onClick={() => this.setIsInitialTour(true)}>Are you new to Mealee?</Button>
            </Alert>
        </Col>
    )
};

export function renderDesktopBody(this: App) {

    return (
        <>
            <Col xs="8" sm="5" md="4" lg="3" xl="3" className="h-100" style={{ minHeight: 0 }}>
                <RecipeSelector
                    plan={this.state.plan}
                    scoring={this.state.recipeStore.scoreBy(this.state.plan, this.state.settings.getScoringFuns(), this.state.rand)}
                    recipeStore={this.state.recipeStore}
                    onEdit={this.editRecipe}
                    highlightedRecipes={this.state.highlightedRecipes} />
            </Col>
            <Col>
                <Container className="pt-2 pl-0 pr-0">
                    <Row>
                        <Col>
                            <Planner
                                recipeStore={this.state.recipeStore}
                                plan={this.state.plan}
                                updatePlan={this.updatePlan} />
                        </Col>
                    </Row>
                    {this.state.plan.getNrRecipes() > 0 &&
                        <Row>
                            <Col className="pl-0">
                                <Button
                                    variant="mealee"
                                    onClick={this.showDialog(Dialog.SavePlanXLS)}>
                                    Export plan &nbsp;
                                <Image src="xlsx_logo.svg" height="18" />
                                </Button>
                                <Button
                                    className="ml-2"
                                    variant="mealee"
                                    onClick={this.showDialog(Dialog.ShoppingList)}>
                                    Show shopping list &nbsp;
                                <Image src="todoist_logo.svg" height="18" />
                                </Button>
                            </Col>
                        </Row>
                    }
                </Container>
            </Col>
        </>
    )
};
