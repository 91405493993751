import { Recipe } from '../model/Recipe';
import { Plan } from '../model/Plan';
import { ScoringFun } from '../model/ScoringFun';
import { Scoring } from '../model/Scoring';

import { RecipeStore } from '../store/RecipeStore';

import { Parser, Expression } from 'expr-eval';

import { Map as IMap } from "immutable";
import _ from 'lodash';

declare module '../store/RecipeStore' {
    interface RecipeStore {
        scoreBy(plan: Plan, scoringFuns: ScoringFun[], rand: IMap<string, number>): Scoring
    }
}

function labelDict(r: Recipe) {
    return _.countBy(r.getLabels().map(l => l.toUpperCase()));
}

function _scoreRecipes(e: Expression, recipes: Recipe[], fun: ScoringFun, rand: IMap<string, number>) {
    return new Map(recipes.map(r => {

        let s = e.evaluate({
            // Dirty trick to be able to pass Recipe type
            "this": r as unknown as string,
            "rrandom": rand.get(r.getId()) || 0
        });
        return [r, s]
    }));
}

RecipeStore.prototype.scoreBy = function (plan: Plan, scoringFuns: ScoringFun[], rand: IMap<string, number>) {
    let p = new Parser();

    let recipes = this.all();
    let rlabels = new Map(recipes.map(r => [r, labelDict(r)]))
    let precipeids = plan.getAllPlannedRecipes().map(r => r.getRecipeId())
    let precipes = _.countBy(precipeids)
    let plabels = _.countBy(_.flatMap(this.getAll(precipeids), r => r.getLabels()).map(l => l.toUpperCase()))

    p.functions.intersection = (as: any[], bs: any[]) => {
        return _.intersection(as, bs);
    }

    p.functions.sum = (as: number[]) => {
        return _.sum(as);
    }

    p.functions.ingredients = (r: Recipe) => {
        return r.getIngredients();
    }

    p.functions.hasLabel = (r: Recipe, label: string) => {
        let ld = rlabels.get(r);
        if (ld) {
            return ld[label] > 0;
        }
        else {
            return false;
        }
    }

    p.functions.countLabelInPlan = (label: string) => {
        return plabels[label] ?? 0;
    }

    p.functions.countRecipeInPlan = (r: Recipe) => {
        return precipes[r.getId()] ?? 0;
    }

    let scoring = new Scoring()

    for (let sf of scoringFuns) {
        let e = p.parse(sf.getScript())

        let max = -1;

        p.functions.normalize = (curr: number) => {
            if (curr > max) {
                max = curr;
            }

            return curr;
        }

        let scores = _scoreRecipes(e, recipes, sf, rand);

        // If `normalize` was used, we need to run it again
        if (max > 0) {
            p.functions.normalize = (curr: number) => {
                return curr / max;
            }

            scores = _scoreRecipes(e, recipes, sf, rand);
        }

        for (let [r, s] of scores) {
            scoring.addScoring(r.getId(), sf, s);
        }
    }

    return scoring;
}
