import React, { FunctionComponent } from "react";

import { Navbar, Nav, Image } from "react-bootstrap";

function getVersionNumber() {
    return `${process.env.REACT_APP_VERSION}`;
}

export const MenuBar: FunctionComponent = () => {
    return (
        <Navbar bg="mealee-menu" variant="dark" className="pl-1">
            <Navbar.Brand className="logo mr-2" href="#home">
                <Image src="mealee_logo_v9.svg" height={60} alt="Mealee" />
            </Navbar.Brand>
            <Navbar.Brand style={{ marginTop: 40 }}>
                <span style={{
                    fontFamily: "'Baloo 2', cursive",
                    fontWeight: 500,
                    fontSize: 30
                }}>meal planning for geeks</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="container-fluid">
                    <Nav.Item className="ml-auto">
                        <span style={{
                            fontFamily: "'Baloo 2', cursive",
                            fontWeight: 600,
                            fontSize: 20,
                            color: "#D1EdF230"
                        }}>v{getVersionNumber()}
                        </span>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
