import { Recipe } from '../model/Recipe'
import { Workspace, WorkspaceJSON } from '../model/Workspace'
import { Settings } from '../model/Settings'

declare module '../model/Workspace' {
    interface Workspace {
        serialize(): WorkspaceJSON,
        toBlob(): Blob
    }
}

Workspace.prototype.serialize = function () {
    return {
        fileVersion: 1,
        recipes: this.getRecipes().map((r) => r.toJSON()),
        settings: this.getSettings().toJSON(),
        lastUpdated: this.getLastUpdated().toISOString()
    };
}

Workspace.prototype.toBlob = function () {
    let bytes = new TextEncoder().encode(JSON.stringify(this.serialize()));
    let blob = new Blob([bytes], {
        type: "application/json;charset=utf-8",
    });
    return blob;
}

export function deserializeWorkspace(json: WorkspaceJSON) {

    if (typeof json === "object") {
        let r: WorkspaceJSON = json;
        if (!r.fileVersion) r.fileVersion = 1;

        if (typeof r.fileVersion !== "undefined") {
            if (r.fileVersion !== 1) {
                throw new Error("Invalid recipe file version");
            }

            let lastUpdated = r.lastUpdated ? new Date(Date.parse(r.lastUpdated)) : undefined

            if (Array.isArray(r.recipes)) {
                return new Workspace(
                    r.recipes.map(Recipe.fromJSON),
                    r.settings ? Settings.fromJSON(r.settings) : new Settings(),
                    lastUpdated);
            }
        }
    }

    throw new Error("Invalid mealee file");
}
